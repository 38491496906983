import Hero from "../components/Hero";
import Features from "../components/Features";
import CTA from "../components/CTA";
import Pricing from "../components/Pricing";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import LogoCloud from "../components/LogoCloud";
import Banners from "../components/Banners";
import Testimonials from "../components/Testimonials";
import Header from "../components/Header";
import CommonHead from "../components/CommonHead";

export default function index() {
  return (<div>
    <CommonHead/>
    <Banners/>
    <Header bg={`bg-gray-50`}/>
    <Hero/>
    <Features/>
    <Pricing/>
    {/*<Testimonials/>*/}
    <LogoCloud/>
    <Newsletter/>
    <CTA/>
    <Footer/>
  </div>)
}
